:root{
	--primary: #F4CF47;
  --secondary: #398149;
	--text-primary: #575757;
	--text-secondary: #FAFAFA;
	--text-light: #b1b3b3;  
  --border: #e5e5e5;
  --background: #969696;
	--background-dark: #dcdcdc;
  --background-light: #FAFAFA;
  --white: #ffffff;
  --dark-grey: #1c1c1c;
  --error-color: #cb352d;
  --black: #000000;

  --news-item-background: #ffffff;
}
