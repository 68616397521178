@font-face {
  font-family: 'FuturaStd-Bold';
  src: local('FuturaStd-Bold'), url('../../assets/fonts/FuturaStd-Bold.woff') format('woff');
}
@font-face {
  font-family: 'FuturaStd-Medium';
  src: local('FuturaStd-Medium'), url('../../assets/fonts/FuturaStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'FuturaStd-Book';
  src: local('FuturaStd-Book'), url('../../assets/fonts/FuturaStd-Book.woff') format('woff');
}
